import {
  FiBook,
  FiBookOpen,
  FiCompass,
  FiGift,
  FiList,
  FiLogIn,
  FiMonitor,
  FiPhoneIncoming,
  FiShoppingCart,
  FiTrendingUp,
  FiUser,
  FiWind,
} from 'react-icons/fi'

const initialState = [
  {
    title: 'Admin',
    items: [
      {
        url: '/',
        icon: <FiCompass size={20} />,
        title: 'Dashboard',
        items: [],
      },
    ],
  },
  {
    title: 'Users',
    items: [
      {
        url: '/',
        icon: <FiUser size={20} />,
        title: '회원',
        items: [
          {
            url: '/users',
            title: '회원 목록',
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Courses',
    items: [
      {
        url: '/',
        icon: <FiBook size={20} />,
        title: '강의',
        items: [
          {
            url: '/courses/create',
            title: '강의 생성',
            items: [],
          },
          {
            url: '/courses',
            title: '강의 목록',
            items: [],
          },
        ],
      },
      {
        url: '/',
        icon: <FiBookOpen size={20} />,
        title: '지원서',
        items: [
          {
            url: '/applies',
            title: '지원서 목록',
            items: [],
          },
        ],
      },
      {
        url: '/',
        icon: <FiGift size={20} />,
        title: '프로모션',
        items: [
          {
            url: '/promotions',
            title: '프로모션 목록',
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Showroom',
    items: [
      {
        url: '/',
        icon: <FiMonitor size={20} />,
        title: '배너',
        items: [
          {
            url: '/banners',
            title: '배너 목록',
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Progress',
    items: [
      {
        url: '/',
        icon: <FiTrendingUp size={20} />,
        title: '진도',
        items: [
          {
            url: '/progress',
            title: '진도 목록',
            items: [],
          },
          {
            url: '/player-logs',
            title: '강의 재생 이벤트',
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Payments',
    items: [
      {
        url: '/',
        icon: <FiLogIn size={20} />,
        title: '수강',
        items: [
          {
            url: '/enrollments/create',
            title: '수강 생성',
            items: [],
          },
          {
            url: '/enrollments',
            title: '수강 목록',
            items: [],
          },
        ],
      },
      {
        url: '/',
        icon: <FiShoppingCart size={20} />,
        title: '결제',
        items: [
          {
            url: '/payments',
            title: '결제 목록',
            items: [],
          },
          {
            url: '/carts',
            title: '장바구니',
            items: [],
          },
          {
            url: '/purchase-orders',
            title: '맞춤결제 목록',
            items: [],
          },
        ],
      },
      {
        url: '/',
        icon: <FiWind size={20} />,
        title: '환불',
        items: [
          {
            url: '/refund/create',
            title: '환불생성',
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Coupons',
    items: [
      {
        url: '/',
        icon: <FiGift size={20} />,
        title: '쿠폰',
        items: [
          {
            url: '/coupons',
            title: '쿠폰 목록',
            items: [],
          },
        ],
      },
      {
        url: '/',
        icon: <FiGift size={20} />,
        title: '이용권',
        items: [
          {
            url: '/vouchers',
            title: '이용권 목록',
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: 'CRM',
    items: [
      {
        url: '/',
        icon: <FiPhoneIncoming size={20} />,
        title: '1:1 문의',
        items: [
          {
            url: '/inquiries',
            title: '문의 목록',
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Archived',
    items: [
      {
        url: '/',
        icon: <FiList size={20} />,
        title: 'D-Board Menus',
        items: [
          {
            title: 'Applications',
            items: [
              {
                url: '/themes/dashboard',
                title: 'Dashboard',
                items: [],
              },
              {
                url: '/',
                title: 'Apps',
                items: [
                  {
                    url: '/themes/social-feed',
                    title: 'Social feed',
                    items: [],
                  },
                  {
                    url: '/themes/tasks',
                    title: 'Tasks',
                    items: [],
                  },
                  {
                    url: '/themes/inbox',
                    title: 'Inbox',
                    items: [],
                  },
                  {
                    url: '/themes/kanban',
                    title: 'Kanban',
                    items: [],
                  },
                  {
                    url: '/themes/todo',
                    title: 'Todo',
                    items: [],
                  },
                ],
              },
              {
                url: '/',
                title: 'Menu levels',
                items: Array.from(Array(4).keys()).map(i => {
                  return {
                    url: '/themes',
                    title: `Level 1-${i + 1}`,
                    items: Array.from(Array(4).keys()).map(j => {
                      return {
                        url: '/themes',
                        title: `Level 2-${j + 1}`,
                        items: Array.from(Array(4).keys()).map(k => {
                          return {
                            url: '/themes',
                            title: `Level 3-${k + 1}`,
                            items: Array.from(Array(4).keys()).map(l => {
                              return {
                                url: '/themes',
                                title: `Level 4-${l + 1}`,
                                items: [],
                              }
                            }),
                          }
                        }),
                      }
                    }),
                  }
                }),
              },
              {
                url: '/',
                title: 'Demos',
                badge: {
                  color: 'bg-indigo-500 text-white',
                  text: 6,
                },
                items: [
                  {
                    url: '/themes/demo-1',
                    title: 'Light background',
                    items: [],
                  },
                  {
                    url: '/themes/demo-2',
                    title: 'Dark background',
                    items: [],
                  },
                  {
                    url: '/themes/demo-4',
                    title: 'Dark sidebar',
                    items: [],
                  },
                  {
                    url: '/themes/demo-3',
                    title: 'Small sidebar',
                    items: [],
                  },
                  {
                    url: '/themes/demo-5',
                    title: 'Dark small sidebar',
                    items: [],
                  },
                  {
                    url: '/themes/demo-6',
                    title: 'Dark navbar',
                    items: [],
                  },
                ],
              },
              {
                url: '/',
                title: 'E-commerce',
                items: [
                  {
                    url: '/themes/e-commerce',
                    title: 'Products',
                    items: [],
                  },
                  {
                    url: '/themes/invoice',
                    title: 'Invoice',
                    items: [],
                  },
                  {
                    url: '/themes/pricing-tables',
                    title: 'Pricing tables',
                    items: [],
                  },
                ],
              },
            ],
          },
          {
            title: 'Components',
            items: [
              {
                url: '/',
                title: 'UI Elements',
                items: [
                  {
                    url: '/themes/badges',
                    title: 'Badges',
                    items: [],
                  },
                  {
                    url: '/themes/breadcrumbs',
                    title: 'Breadcrumbs',
                    items: [],
                  },
                  {
                    url: '/themes/buttons',
                    title: 'Buttons',
                    items: [],
                  },
                  {
                    url: '/themes/dropdowns',
                    title: 'Dropdowns',
                    items: [],
                  },
                  {
                    url: '/themes/images',
                    title: 'Images',
                    items: [],
                  },
                  {
                    url: '/themes/lists',
                    title: 'Lists',
                    items: [],
                  },
                  {
                    url: '/themes/progress-bars',
                    title: 'Progress bars',
                    items: [],
                  },
                  {
                    url: '/themes/pagination',
                    title: 'Pagination',
                    items: [],
                  },
                  {
                    url: '/themes/tabs',
                    title: 'Tabs',
                    items: [],
                  },
                  {
                    url: '/themes/typography',
                    title: 'Typography',
                    items: [],
                  },
                ],
              },
              {
                url: '/',
                title: 'Forms',
                badge: {
                  color: 'bg-indigo-500 text-white',
                  text: 6,
                },
                items: [
                  {
                    url: '/themes/default-forms',
                    title: 'Default forms',
                    items: [],
                  },
                  {
                    url: '/themes/sample-forms',
                    title: 'Sample forms',
                    items: [],
                  },
                  {
                    url: '/themes/sliders',
                    title: 'Sliders',
                    items: [],
                  },
                  {
                    url: '/themes/datepicker',
                    title: 'Date picker',
                    items: [],
                  },
                  {
                    url: '/themes/switches',
                    title: 'Switches',
                    items: [],
                  },
                  {
                    url: '/themes/steps',
                    title: 'Form steps',
                    items: [],
                  },
                  {
                    url: '/themes/validation',
                    title: 'Form validation',
                    items: [],
                  },
                ],
              },
              {
                url: '/',
                title: 'Tables',
                items: [
                  {
                    url: '/themes/default-tables',
                    title: 'Default tables',
                    items: [],
                  },
                  {
                    url: '/themes/datatables',
                    title: 'Datatables',
                    items: [],
                  },
                ],
              },
              {
                url: '/',
                title: 'Notifications',
                badge: {
                  color: 'bg-indigo-500 text-white',
                  text: 2,
                },
                items: [
                  {
                    url: '/themes/alerts',
                    title: 'Alerts',
                    items: [],
                  },
                  {
                    url: '/themes/notifications',
                    title: 'Notifications',
                    items: [],
                  },
                  {
                    url: '/themes/modals',
                    title: 'Modals',
                    items: [],
                  },
                  {
                    url: '/themes/popovers',
                    title: 'Popovers',
                    items: [],
                  },
                  {
                    url: '/themes/tooltips',
                    title: 'Tooltips',
                    items: [],
                  },
                ],
              },
            ],
          },
          {
            title: 'Pages',
            items: [
              {
                url: '/',
                title: 'Authentication',
                badge: {
                  color: 'bg-indigo-500 text-white',
                  text: 7,
                },
                items: [
                  {
                    url: '/themes/contact-us-1',
                    title: 'Contact us',
                    items: [],
                  },
                  {
                    url: '/themes/login-1',
                    title: 'Login 1',
                    items: [],
                  },
                  {
                    url: '/themes/login-2',
                    title: 'Login 2',
                    items: [],
                  },
                  {
                    url: '/themes/login-3',
                    title: 'Login 3',
                    items: [],
                  },
                  {
                    url: '/themes/create-account',
                    title: 'Create account',
                    items: [],
                  },
                  {
                    url: '/themes/email-confirmation',
                    title: 'Email confirmation',
                    items: [],
                  },
                  {
                    url: '/themes/logout',
                    title: 'Logout',
                    items: [],
                  },
                  {
                    url: '/themes/reset-password',
                    title: 'Reset password',
                    items: [],
                  },
                  {
                    url: '/themes/forgot-password',
                    title: 'Forgot password',
                    items: [],
                  },
                  {
                    url: '/themes/lock-screen',
                    title: 'Lock screen',
                    items: [],
                  },
                  {
                    url: '/themes/subscribe',
                    title: 'Subscribe',
                    items: [],
                  },
                ],
              },
              {
                url: '/',
                title: 'User',
                items: [
                  {
                    url: '/themes/user-profile',
                    title: 'User profile',
                    items: [],
                  },
                  {
                    url: '/themes/social-feed',
                    title: 'Social feed',
                    items: [],
                  },
                ],
              },
              {
                url: '/',
                title: 'Pages',
                items: [
                  {
                    url: '/themes/support-1',
                    title: 'Support',
                    items: [],
                  },
                  {
                    url: '/themes/empty-page',
                    title: 'Empty page',
                    items: [],
                  },
                  {
                    url: '/themes/terms-of-service',
                    title: 'Terms of service',
                    items: [],
                  },
                  {
                    url: '/themes/privacy-policy',
                    title: 'Privacy policy',
                    items: [],
                  },
                  {
                    url: '/themes/error-page',
                    title: 'Error page',
                    items: [],
                  },
                  {
                    url: '/themes/coming-soon',
                    title: 'Coming soon',
                    items: [],
                  },
                ],
              },
            ],
          },
          {
            title: 'Other',
            items: [
              {
                url: '/',
                title: 'Charts',
                badge: {
                  color: 'bg-indigo-500 text-white',
                  text: 4,
                },
                items: [
                  {
                    url: '/themes/bar-charts',
                    title: 'Bar charts',
                    items: [],
                  },
                  {
                    url: '/themes/line-charts',
                    title: 'Line and area charts',
                    items: [],
                  },
                  {
                    url: '/themes/pie-charts',
                    title: 'Pie and doughnut charts',
                    items: [],
                  },
                  {
                    url: '/themes/other-charts',
                    title: 'Other charts',
                    items: [],
                  },
                ],
              },
              {
                url: '/',
                title: 'Icons',
                items: [
                  {
                    url: '/themes/react-icons',
                    title: 'React icons',
                    items: [],
                  },
                  {
                    url: '/themes/country-flags',
                    title: 'Country flags',
                    items: [],
                  },
                ],
              },
            ],
          },
          {
            title: 'Docs',
            items: [
              {
                url: '/themes/documentation',
                title: 'Documentation',
                items: [],
              },
            ],
          },
          {
            title: 'Intro',
            items: [
              {
                url: '/themes/landing',
                title: 'Home page',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
]

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
