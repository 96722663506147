import { AnyAction, combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import { clearProfile, fetchProfile, login } from './actions'
import { IIdTokenPayload, IProfile } from './types'

const isLoading = createReducer<boolean, AnyAction>(false as boolean)
  .handleAction([fetchProfile.request, login.request], () => true)
  .handleAction(
    [fetchProfile.success, fetchProfile.failure, login.success, login.failure],
    () => false,
  )

const profile = createReducer<IProfile | null, AnyAction>(null)
  .handleAction(fetchProfile.success, (state, action) => action.payload)
  .handleAction(clearProfile, () => null)

const idToken = createReducer<IIdTokenPayload | null, AnyAction>(null).handleAction(
  login.success,
  (state, action) => action.payload,
)
const loginFailNoti = createReducer<boolean, AnyAction>(false as boolean)
  .handleAction([login.failure], () => true)
  .handleAction([login.request], () => false)

const authReducer = combineReducers({
  isLoading,
  profile,
  idToken,
  loginFailNoti,
})

export default authReducer
export type IAuthState = ReturnType<typeof authReducer>
