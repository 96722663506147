import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { ThunkAction } from 'redux-thunk'

import createAsyncThunk from '../../utils/createAsyncThunk'
import { RootState } from '../index'
import { fetchProfile, login } from './actions'
import { requestProfile, requestToken } from './api'
import { IAuthAction, IIdTokenPayload } from './types'

export const fetchProfileThunk = createAsyncThunk(fetchProfile, requestProfile)

export const requestLoginThunk = (
  username: string,
  password: string,
): ThunkAction<Promise<void>, RootState, null, IAuthAction> => {
  return async dispatch => {
    const { request, success, failure } = login
    dispatch(request({ username, password }))
    try {
      const { access_token, refresh_token, id_token } = await requestToken(username, password)
      const decodedIdToken = jwtDecode<IIdTokenPayload>(id_token)
      const refreshTokenExpiresAt = new Date().getTime() + 1800000

      localStorage.setItem('access_token', access_token)
      localStorage.setItem('refresh_token', refresh_token)
      localStorage.setItem('refresh_token_expires_at', refreshTokenExpiresAt.toString())

      dispatch(success(decodedIdToken))
      return
    } catch (e) {
      if (axios.isAxiosError(e)) {
        dispatch(failure(e))
      }
    }
  }
}

// export const clearAuth = () => {}
