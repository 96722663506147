import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { FiMenu, FiSettings } from 'react-icons/fi'
import UserProfileDropdown from './user-profile-dropdown'
import Search from './search'

const Navbar = () => {
  const { config } = useSelector(
    state => ({
      config: state.config,
    }),
    shallowEqual,
  )
  let { rightSidebar, collapsed } = { ...config }
  const dispatch = useDispatch()
  return (
    <div className="app-navbar navbar-1 border-b">
      <div className="navbar-inner w-full flex items-center justify-start">
        <button
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed,
            })
          }
          className="mx-4">
          <FiMenu size={20} />
        </button>
        {/*
        <Search />
         */}

        <span className="ml-auto"></span>
        <UserProfileDropdown />
        <button
          className="btn-transparent flex items-center justify-center h-16 w-8 mx-4"
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'rightSidebar',
              value: !rightSidebar,
            })
          }>
          <FiSettings size={18} />
        </button>
      </div>
    </div>
  )
}

export default Navbar
