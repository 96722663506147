import { RootState } from '@stores/index'
import CryptoJS from 'crypto-js'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import AccountLinks from './account-links'

const Dropdown = () => {
  const [hidden, setHidden] = useState(true)

  const {
    auth: { idToken },
  } = useSelector(
    (state: RootState) => ({
      auth: state.auth,
    }),
    shallowEqual,
  )

  const adminInfo = useMemo(() => idToken ?? { name: '', email: '' }, [idToken])
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (buttonRef === null || !buttonRef.current || dropdownRef === null || !dropdownRef.current)
        return
      if (
        hidden ||
        buttonRef.current.contains(event.target as Node) ||
        dropdownRef.current.contains(event.target as Node)
      ) {
        return false
      }
      setHidden(!hidden)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [hidden, dropdownRef, buttonRef])

  const handleDropdownClick = () => {
    setHidden(!hidden)
  }

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={handleDropdownClick}
        className="flex h-16 w-8 rounded-full ml-2 relative">
        <span className="absolute top-0 left-0 pt-3">
          <img
            className="h-8 w-8 rounded-full"
            src={`https://www.gravatar.com/avatar/${CryptoJS.MD5(adminInfo.email).toString()}`}
            alt="avatar"
          />
        </span>
      </button>
      <div
        ref={dropdownRef}
        className={`dropdown absolute top-0 right-0 mt-12 ${hidden ? '' : 'open'}`}>
        <div className="dropdown-content w-48 bottom-end">
          <AccountLinks adminInfo={{ name: adminInfo.name, email: adminInfo.email }} />
        </div>
      </div>
    </div>
  )
}

export default Dropdown
