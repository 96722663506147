import { Divider } from '@mantine/core'
import Link from 'next/link'
import { ReactNode } from 'react'
import { FiHome, FiLogOut, FiUser } from 'react-icons/fi'

interface IAccountLinks {
  adminInfo?: {
    name: string
    email: string
  }
}

export interface IAccountLinksItem {
  url: string
  icon: ReactNode
  iconColor?: string
  name: string
  badge: {
    number: number
    color: string
  } | null
}

const AccountLinks = ({ adminInfo }: IAccountLinks) => {
  const items: IAccountLinksItem[] = [
    {
      url: '/',
      icon: <FiHome size={18} className="stroke-current" />,
      name: 'Home',
      badge: null,
    },
    {
      url: '/mypage',
      icon: <FiUser size={18} className="stroke-current" />,
      name: 'Mypage',
      badge: null,
    },
    {
      url: '/logout',
      icon: <FiLogOut size={18} className="stroke-current" />,
      name: 'Logout',
      badge: null,
    },
  ]

  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
        <li className="px-2 py-4">
          <article className="text-sm font-semibold mb-6">
            <p className="mb-2">
              Name: <span className="font-normal">{adminInfo ? adminInfo.name : 'Anonymous'}</span>
            </p>
            <p>
              E-mail:{' '}
              <span className="font-normal">{adminInfo ? adminInfo.email : 'Anonymous'}</span>
            </p>
          </article>
          <Divider />
        </li>
        {items.map((item, i) => (
          <li key={i} className="dropdown-item">
            <Link href={item.url}>
              <a className="flex flex-row items-center justify-start h-10 w-full px-2">
                {item.icon}
                <span className="mx-2">{item.name}</span>
                {item.badge && (
                  <span
                    className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 items-center justify-center rounded-full ${item.badge.color} ml-auto`}>
                    {item.badge.number}
                  </span>
                )}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AccountLinks
