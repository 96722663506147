import { RootState } from '@stores'
import { IProfile } from '@stores/auth'
import React, { createContext, FC, useContext } from 'react'
import { useSelector } from 'react-redux'

export interface IAuthContextState {
  isSigned: boolean
  profile: IProfile | null
}

const AuthContext = createContext<IAuthContextState>({
  isSigned: false,
  profile: null,
})

export interface IAuthProviderProps {
  children: any
}

const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const { profile } = useSelector((state: RootState) => state.auth)

  return (
    <AuthContext.Provider value={{ isSigned: profile !== null, profile }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
export const useAuth = () => useContext(AuthContext)
