/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'

export default function dashboard(
  state = {
    value: 0,
    conversions: {},
  },
  action: AnyAction,
) {
  switch (action.type) {
    case 'SET_DASHBOARD':
      const { key, value } = action
      return {
        ...state,
        [`${key}`]: value,
      }
    default:
      return state
  }
}
