/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-var-requires */
import { createRouterMiddleware, initialRouterState, routerReducer } from 'connected-next-router'
import { RouterState } from 'connected-next-router/types'
import { AppContext } from 'next/app'
import Router from 'next/router'
import { Context, createWrapper, HYDRATE, MakeStore } from 'next-redux-wrapper'
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  Reducer,
  Store,
} from 'redux'
import logger from 'redux-logger'
import { createMigrate } from 'redux-persist'
import thunk, { ThunkDispatch } from 'redux-thunk'

import authReducer from './auth'
import { IAuthState } from './auth/reducer'
import couponReducer from './coupon'
import { ICouponState } from './coupon/reducer'
import { migrations } from './migrations'
import colors from './theme/colors'
import config from './theme/config'
import dashboard from './theme/dashboard'
import leftSidebar from './theme/left-sidebar'
import navigation from './theme/navigation'
import palettes from './theme/palettes'

const combinedReducer = combineReducers({
  router: routerReducer,
  auth: authReducer,
  coupon: couponReducer,
  dashboard,
  colors,
  config,
  leftSidebar,
  navigation,
  palettes,
})

const reducer = (state: RootState, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    }
    if (typeof window !== 'undefined' && state?.router) {
      nextState.router = state.router
    }
    return nextState
  } else {
    return combinedReducer(state, action)
  }
}

const bindMiddleware = (middleware: Middleware[]) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware, logger))
  }
  return applyMiddleware(...middleware)
}

export interface IStoreState {
  router: RouterState
  auth: IAuthState
  coupon: ICouponState
}

const makeStore: MakeStore<Store<any, AnyAction>> = (context: Context) => {
  const routerMiddleware = createRouterMiddleware()
  const { asPath } = (context as AppContext).ctx || Router.router || {}
  let initialState
  if (asPath) initialState = { router: initialRouterState(asPath) }

  const isServer = typeof window === 'undefined'

  if (isServer) {
    return createStore(reducer as Reducer, initialState, bindMiddleware([thunk, routerMiddleware]))
  } else {
    const { persistStore, persistReducer } = require('redux-persist')
    const storage = require('redux-persist/lib/storage').default

    const persistConfig = {
      key: 'globAdminPersistedStore',
      storage,
      version: 0,
      migrate: createMigrate(migrations, { debug: true }),
      whitelist: ['auth'],
    }
    const persistedReducer = persistReducer(persistConfig, reducer)

    const store = createStore(
      persistedReducer,
      initialState,
      bindMiddleware([thunk, logger, routerMiddleware]),
    )
    // @ts-ignore
    store.__persistor = persistStore(store)

    return store
  }
}

export const wrapper = createWrapper(makeStore, {
  debug: process.env.NODE_ENV === 'development',
})

export type RootState = ReturnType<typeof combinedReducer>
export type AppThunkDispatch = ThunkDispatch<RootState, void, AnyAction>
