import axios from 'axios'
import qs from 'qs'

import { IProfile, IRequestTokenResp } from './types'

export const requestProfile = async () => {
  const resp = await axios.get<IProfile>(`${window.apiEndpoint}/me`)
  return resp.data
}

export const requestToken = async (username: string, password: string) => {
  const data = qs.stringify({
    username,
    password,
    grant_type: 'password',
    scope: 'openid email profile',
  })

  const resp = await axios({
    method: 'POST',
    data,
    url: '/api/oauth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  const { access_token, refresh_token, id_token } = resp.data as IRequestTokenResp

  return { access_token, refresh_token, id_token }
}
