import { AxiosError } from 'axios'
import { createAction, createAsyncAction } from 'typesafe-actions'

import { IIdTokenPayload, IProfile } from './types'

export const fetchProfile = createAsyncAction(
  'auth/FETCH_PROFILE',
  'auth/FETCH_PROFILE_SUCCESS',
  'auth/FETCH_PROFILE_FAIL',
)<unknown, IProfile, AxiosError>()

export const clearAuthToken = createAction('auth/CLEAR_ID_TOKEN')<void>()
export const clearProfile = createAction('auth/CLEAR_PROFILE')<void>()
export const login = createAsyncAction('auth/LOGIN', 'auth/LOGIN_SUCCESS', 'auth/LOGIN_FAIL')<
  unknown,
  IIdTokenPayload,
  AxiosError
>()
