import { AnyAction } from 'redux'

export default function config(
  state = {
    name: '멋쟁이사자처럼',
    description: 'Next.js Tailwind CSS admin template',
    url: 'https://admin.likelion.net',
    layout: 'layout-1',
    collapsed: false,
    rightSidebar: false,
    backdrop: false,
  },
  action: AnyAction,
) {
  switch (action.type) {
    case 'SET_CONFIG':
      return {
        ...state,
        ...action.config,
      }
    case 'SET_CONFIG_KEY':
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${action.key}`]: action.value,
      }
    default:
      return state
  }
}
