export const migrations = {
  '0': (state: any) => {
    return {
      ...state,
      router: {
        location: {
          href: '/',
          pathname: '/',
          search: '',
          hash: '',
        },
      },
      auth: {
        isLoading: false,
        profile: null,
      },
      dashboard: {
        value: 0,
        conversions: {},
      },
      colors: [
        'transparent',
        'black',
        'white',
        'gray',
        'red',
        'yellow',
        'green',
        'blue',
        'indigo',
        'purple',
        'pink',
      ],
      config: {
        name: 'D-board',
        description: 'Next.js Tailwind CSS admin template',
        url: 'https://d-board-nextjs.mobifica.com',
        layout: 'layout-1',
        collapsed: false,
        rightSidebar: false,
        backdrop: false,
      },
      leftSidebar: {
        showButtonText: true,
        showSectionTitle: true,
        showLogo: true,
        showCard: true,
        showAccountLinks: false,
        showProjects: true,
        showTags: true,
        card: 1,
      },
      palettes: {
        background: 'light',
        leftSidebar: 'light',
        navbar: 'light',
        rightSidebar: 'light',
      },
      _persist: {
        version: -1,
        rehydrated: true,
      },
    }
  },
}
