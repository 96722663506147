import { AnyAction, combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import { fetchCoupons } from './actions'

const isLoading = createReducer<boolean, AnyAction>(false as boolean)
  .handleAction([fetchCoupons.request], () => true)
  .handleAction([fetchCoupons.success, fetchCoupons.failure], () => false)

const coupons = createReducer<any, AnyAction>([]).handleAction(
  fetchCoupons.success,
  (state, action) => action.payload,
)

const couponReducer = combineReducers({
  isLoading,
  coupons,
})

export default couponReducer
export type ICouponState = ReturnType<typeof couponReducer>
