/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/animate.css'
import '../css/components/buttons.css'
import '../css/components/datepicker.css'
import '../css/components/dropdowns.css'
import '../css/components/forms.css'
import '../css/components/left-sidebar-1/styles-lg.css'
import '../css/components/left-sidebar-1/styles-sm.css'
import '../css/components/modals.css'
import '../css/components/navbar.css'
import '../css/components/nprogress.css'
import '../css/components/recharts.css'
import '../css/components/right-sidebar.css'
import '../css/components/sliders.css'
import '../css/components/steps.css'
import '../css/components/tables.css'
import '../css/components/tabs.css'
import '../css/components/user-widgets/widget-2.css'
import '../css/components/user-widgets/widget-4.css'
import '../css/layouts/layout-1.css'
import '../css/main.css'
import '../css/tailwind.css'
import '../utils/axios'

import { MantineProvider } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConnectedRouter } from 'connected-next-router'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { useStore } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Layout from '../layouts'
import AuthProvider from '../shared/contexts/Auth'
import { wrapper } from '../stores'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

export interface IAppProps extends AppProps {
  config: IPublicConfig | null
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
      refetchOnWindowFocus: false,
    },
  },
})

const WrapperApp = ({ Component, pageProps, config }: IAppProps): JSX.Element => {
  const store = useStore()

  useEffect(() => {
    if (!config) return
    if (typeof window.apiEndpoint === 'undefined') {
      window.apiEndpoint = config.apiEndpoint
    }
  }, [config])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>
      <PersistGate
        // @ts-ignore
        persistor={store.__persistor}
        loading={<div className="preloader" style={{ opacity: 0.494764 }}></div>}>
        <AuthProvider>
          <ConnectedRouter>
            <QueryClientProvider client={queryClient}>
              <MantineProvider>
                <NotificationsProvider position="top-right" zIndex={2077}>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </NotificationsProvider>
              </MantineProvider>
            </QueryClientProvider>
          </ConnectedRouter>
        </AuthProvider>
      </PersistGate>
    </>
  )
}

WrapperApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  let config = null
  if (typeof window === 'undefined') {
    const allConfig: IConfig = require('../utils/config').default
    config = {
      oauthAuthorizationEndpoint: allConfig.oauthAuthorizationEndpoint,
      oauthClientId: allConfig.oauthClientId,
      oauthScope: allConfig.oauthScope,
      apiEndpoint: allConfig.apiEndpoint,
      oauthRedirectUrl: allConfig.oauthRedirectUrl,
    }
  }

  return { ...appProps, config }
}

export default wrapper.withRedux(WrapperApp)
